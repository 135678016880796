<template>
  <section class="owner">
    <div class="grid">
      <LazyZAvatar
        class="avatar mr-md-3"
        :to="localePath({ name: 'profile-id', params: { id: rvOwnerId }, query: { cta: 'listing-owner-section' } })"
        :text="initials"
        :src="rvOwnerAvatar"
        :size="isMediumBreakpoint ? 'xl' : 'md'"
      >
        <template #badge>
          <IconSuperhost
            v-if="rvIsSuperHostActive"
            class="superhost"
          />
        </template>
      </LazyZAvatar>
      <div class="name">
        <div>
          <strong>{{ t('hostedBy', { name: rvOwnerFirstName }) }}</strong>
        </div>

        <div
          v-if="rvOwnerCreatedAt"
          class="joined"
        >
          {{ t('joinedIn', { date: dates.utc(rvOwnerCreatedAt).format('YYYY') }) }}
        </div>
      </div>
      <div class="stats">
        <UserStatIdentityVerified v-if="rvOwnerVerified" />
        <LazyUserStatResponseRate
          v-if="rvOwnerScore !== null"
          :score="rvOwnerScore"
        />
        <LazyUserStatSuperHost v-if="rvIsSuperHostActive" />
      </div>
      <div
        v-if="rvOwnerAbout"
        class="about"
      >
        <AppCollapsibleContent
          :content="rvOwnerAbout"
          :line-clamp="3"
          more-button-variant="primary"
        />
      </div>
      <div
        data-testid="host-contact-section"
        class="contact"
      >
        <RvRentalAskOwnerQuestion />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const {
  rvOwnerAvatar,
  rvOwnerFirstName,
  rvOwnerId,
  rvOwnerLastName,
  rvOwnerVerified,
  rvOwnerScore,
  rvOwnerAbout,
  rvOwnerCreatedAt,
  rvIsSuperHostActive,
} = useRvDetails()

const { t } = useI18n()
const localePath = useLocalePath()
const { isMediumBreakpoint } = useBreakpoint()

const initials = computed(() => formatNameAsInitials(rvOwnerFirstName.value, rvOwnerLastName.value))
</script>

<style lang="scss" scoped>
section.owner {
  color: getColor('primary-500');

  .grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: fit-content(6.5rem) 1fr fit-content(200px);
    grid-template-areas:
      'avatar info contact'
      'avatar stats stats'
      'bio bio bio';

    @include media-max-size(medium) {
      grid-template-columns: 1fr fit-content(3.5rem);
      grid-template-areas:
        'info avatar'
        'stats stats'
        'bio bio'
        'contact contact';
    }

    .avatar {
      grid-area: avatar;
    }

    .about {
      grid-area: bio;
      color: getColor('primary-350');
      @include media-max-size(medium) {
        padding: 1rem 0;
      }
    }

    .name {
      grid-area: info;

      strong {
        @include strong-2;
      }
    }

    .contact {
      grid-area: contact;
      display: flex;
      align-items: center;
    }

    .stats {
      grid-area: stats;
      display: flex;
      flex-wrap: wrap;
      grid-gap: 0.5rem;

      @include media-min-size(medium) {
        grid-gap: 1rem;
      }

      > span {
        @include media-max-size(medium) {
          width: 100%;
          flex-grow: 1;
        }
      }
    }
  }
}

.superhost {
  max-width: 2.4375rem;
  max-height: 3.125rem;

  @include media-max-size(medium) {
    max-width: 1.625rem;
    max-height: 2.0625rem;
    right: -0.4rem;
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "hostedBy": "Hosted by {name}",
    "contactHost": "Ask a question",
    "joinedIn": "Joined in {date}"
  },
  "fr": {
    "hostedBy": "Proposé par {name}",
    "contactHost": "Poser une question",
    "joinedIn": "Membre depuis {date}"
  }
}
</i18n>
